.breadcrumbs {
    display: flex;
    margin: 15px 0 25px 0;
    .breadcrumb {
        display: flex;
        align-items: center;
        color: $color-black;
        font-size: $font-small;
        text-decoration: none;
        @include navigation-link-hover;
        @media (min-width: 1024px) {
            font-size: $font-normal;
        }
        span {
            margin: 0 10px;
            cursor: auto;
            color: $color-black;
            @media (min-width: 1024px) {
                margin: 0 15px;
            }
        }

        img {
            width: 20px;
            height: 20px;
        }
        &:last-child {
            span {
                display: none;
            }
        }
        &:hover {
            span {
                color: $color-black;
            }
        }
    }
    @media (min-width: 1024px) {
        margin: 20px 0 35px 0;
    }
}