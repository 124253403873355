.footer {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-top: 20px;  
    padding: 0 25px;
  
    @media (min-width: 1024px) {
      flex-wrap: nowrap;
      margin-top: 56px;
      padding: 0 40px;
    }
  
    .footer-section:first-child {
      width: 100%;
      margin-bottom: 20px;
      @media (min-width: 1024px) {
        width: auto;
        margin: 0;
      }
    }
  
    .footer-section:last-child {
      width: 50%;
      @media (min-width: 1024px) {
        margin: 0;
        width: auto;
      }
    }
  
    .footer-section {
      font-size: $font-small1;
      line-height: 14px;
      color: $color-grey-dark;
      @media (min-width: 768px) {
        font-size: $font-normal;
        line-height: 20px;
      }
  
      &__title {
        font-weight: 600;
        font-size: $font-small;
        margin-bottom: 6px;
        color: $color-black;
        @media (min-width: 768px) {
          font-size: $font-large1;
          line-height: 27px;
          margin-bottom: 14px;
        }
      }
  
      &__info {
        max-width: 200px;
        @media (min-width: 768px) {
          max-width: 450px;
        }
      }
  
      &__links {
        a {
          display: block;
          margin: 4px 0;
          text-decoration: none;
          color: $color-grey-dark;
          @include navigation-link-hover;
          @media (min-width: 768px) {
            margin: 9px 0;
          }
        }
      }
  
      &__contacts {
        span {
          font-weight: 600;
        }
        p {
          margin: 4px 0;
          @media (min-width: 768px) {
            margin: 9px 0;
          }
        }
        a {
          overflow-wrap: break-word;
          color: $color-grey-dark;
          text-decoration: none;
          @include navigation-link-hover;
        }
      }
    }
  }
  
  
  