.create-product {
    width: 100%;

    display: flex;
    align-items: center;
    margin-top: 40px;

    @media (min-width: 768px) {
        margin-top: 70px;
    }

    .ng-submitted{
        .create-product__input.ng-invalid.ng-untouched {
            border-color: red;
        }
    }

    &__icon {
        svg {
          width: 30px;
          height: 30px;
          fill: red;
          @include link-hover;
        }
      }

      &__icon.cross {
        position: absolute;
        top: 10px;
        right: 10px;
        svg:hover {
          fill: red;
        }
      }

    &__images {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        margin-bottom: 20px;

        &-arr {
            display: flex;
            margin-top: 20px;
            flex-wrap: wrap;
        }

        &-image {
            position: relative;
            height: 140px;
            width: 140px;
            margin-right: 25px;
            margin-bottom: 10px;
            img {
                width: 100%;
                height: 100%;
            }
        }
    }

    &__wrapper {
        margin: 0 auto;
        width: 90%;
        background-color: #fff;
        padding: 40px;
        border-radius: 10px;
        box-shadow: rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px;
        text-align: center;

        @media (min-width: 768px) {
            width: 80%;
        }
    }

    &__header {
        font-size: $font-large1;
        margin-bottom: 10px;
        text-align: left;

        @media (min-width: 768px) {
            font-size: $font-big1;
            margin-bottom: 30px;
        }
    }

    &__form {
        display: flex;
        flex-direction: column;

        &.edit {
            .ng-submitted.ng-invalid{
                .create-product__input.ng-invalid {
                    border-color: red;
                }
            }
        }

        &-invalid {
            background-color: #fce4e4;
            border: 1px solid #fcc2c3;
            padding: 8px 10px;

            text-align: left;
            color: #cc0033;
            font-size: $font-normal;
            font-weight: 400;
            text-shadow: 1px 1px rgba(250,250,250,.3);

            &.err-message {
                margin-bottom: 10px;
                margin-top: -10px;
            }
        }
    }

    &__label {
        @include form-label;
    }

    &__input {
        @include form-input;
        
        @media (min-width: 768px) {
            margin-bottom: 20px;
        }
    }

    &__button {
        @include form-submit-button;
    }

}