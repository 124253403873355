.product-details {
    display: flex;
    flex-direction: column;
    @media (min-width: 1024px) {
        flex-direction: row;
    }

    &__carousel {
        @media (min-width: 1024px) {
            width: 60%;
            margin-top: 60px;
        }
    }

    &__descr {
        &-title {
            margin-bottom: 12px;
            @media (min-width: 1024px) {
                margin-bottom: 22px;
            }
        }
        margin-top: 15px;
        font-size: $font-small;
        @media (min-width: 1024px) {
            width: 70%;
            margin-top: 30px;
            font-size: $font-normal1;
        }
     
    }

    &__currency {
        cursor: pointer;
        width: 80%;
        position: relative;
        margin-top: 15px;
        @media (min-width: 1024px) {
            margin-top: 25px;
        }
        &-value {
            padding: 6px 30px;
            color: $color-green;
            border: 1px solid $color-green;
            text-align: center;
            border-radius: 20px;
            font-size: $font-small;
            text-transform: uppercase;
            @media (min-width: 1024px) {
                padding: 8px 30px;
                font-size: $font-large;
            }
        }
        &-dropdown {
            display: none;
            position: relative;
            border: 1px solid $color-green;
            border-radius: 17px;
            top: -32px;
            padding: 32px 0 8px;
            @media (min-width: 1024px) {
                padding: 50px 0 10px;
                top: -44px;
                border-radius: 20px;
            }
            &-value {
                color: $color-green;
                font-size: $font-small;
                text-align: center;
                margin-top: 5px;
                text-transform: uppercase;
                @media (min-width: 1024px) {
                    font-size: $font-large;
                    line-height: 40px;
                }
                &:hover {
                    background-color: #f6fef6;
                }
            }
            &.active {
                display: block;
                animation: growDown 300ms ease-in-out forwards;
                transform-origin: top center;
            }
        }
    }

    &__info {
        
        margin: 20px;
        @media (min-width: 1024px) {
            width: 40%;
            margin: 80px 0 0 55px;
        }
        color: $color-black;
        &-name, &-price {
            font-weight: 700;
            font-size: $font-large;
            @media (min-width: 1024px) {
                font-size: $font-big1;
            }
        }
        &-name {
            margin-bottom: 5px;
            @media (min-width: 1024px) {
                margin-bottom: 12px;
            }
        }
        &-price {
            margin-bottom: 16px;
            @media (min-width: 1024px) {
                margin-bottom: 40px;
            }
        }
        &-text {
            font-size: $font-small;
            margin-bottom: 12px;
            @media (min-width: 1024px) {
                font-size: $font-normal1;
                margin-bottom: 22px;
            }
            span {
                text-transform: lowercase;
                &:not(:last-child):after {
                    content: ",";
                  }
            }
        }
    }
}

