.form-req {
  margin-top: 23px;
  @media (min-width: 1024px) {
    margin-top: 40px;
  }

  .ng-submitted.ng-invalid{
    .form-req__input.ng-invalid {
        border-color: red;
    }
    .form-req__textarea.ng-invalid {
      border-color: red;
    }
  }

  &__error {
    background-color: #fce4e4;
    border: 1px solid #fcc2c3;
    padding: 2px 4px;
    margin-top: 10px;
    margin-bottom: 5px;
    text-align: left;
    color: #cc0033;
    font-size: $font-normal;
    font-weight: 400;
    text-shadow: 1px 1px rgba(250,250,250,.3);
    font-size: $font-small;

    @media (min-width: 1024px) {
      margin-top: 15px;
      padding: 8px 10px;
      margin-bottom: 10px;
      max-width: 40%;
    }
  }

  &__success {
    background-color: rgba(62, 189, 97,0.2);
    border:1px solid #3ebd61;
    padding: 2px 4px;
    margin-top: 10px;
    margin-bottom: 5px;
    text-align: left;
    color: green;
    font-size: $font-normal;
    font-weight: 400;
    text-shadow: 1px 1px rgba(250,250,250,.3);
    font-size: $font-small;

    @media (min-width: 1024px) {
      margin-top: 15px;
      padding: 8px 10px;
      margin-bottom: 10px;
      max-width: 40%;
    }
}

  &__header {
    @include header;
  }

  &__input {
    font-size: $font-small1;
    line-height: 14px;
    color: $color-black;
    border-radius: 25px;
    border: 1px solid $color-black;
    padding: 6px 12px;
    width: 100%;

    @media (min-width: 1024px) {
      font-size: $font-normal1;
      line-height: 20px;
      border-radius: 60px;
      padding: 10px 20px;
    }

    &-wrapper {
      margin-bottom: 12px;
      margin-right: 5%;
      width: 45%;
      @media (min-width: 1024px) {
        margin-bottom: 20px;
        margin-right: 54px;
        width: 30%;
      }
    }
  }

  &__contacts {
    display: flex;
    flex-wrap: wrap;
    margin-top: 12px;
    @media (min-width: 1024px) {
      margin-top: 30px;
    }
  }

  &__textarea {
    font-size: $font-small1;
    line-height: 14px;
    color: $color-black;
    border-radius: 20px;
    border: 1px solid $color-black;
    padding: 6px 12px;
    width: 100%;

    @media (min-width: 1024px) {
      font-size: $font-normal1;
      line-height: 20px;
      border-radius: 35px;
      padding: 15px 20px;
    }

    &-wrapper {
      margin-bottom: 12px;
      @media (min-width: 1024px) {
        margin-bottom: 20px;
      }
    }
  }

  &__input-title, .form-req__textarea-title {
    font-size: $font-small1;
    line-height: 14px;
    color: $color-black;
    margin-bottom: 2px;
    @media (min-width: 1024px) {
      font-size: $font-normal1;
      line-height: 20px;
      margin-bottom: 5px;
    }
  }
  &__submit-button {
    color: $color-white;
    font-size: $font-small1;
    line-height: 14px;
    text-align: center;
    background-color: $color-black;
    padding: 6px 12px;
    border-radius: 25px;
    border: none;
    cursor: pointer;

    @media (min-width: 1024px) {
      padding: 10px 20px;
      font-size: $font-normal1;
      line-height: 20px;
      border-radius: 60px;
    }
  }

}

.textarea {
  &-wrapper1 {
    width: 45%;
    @media (min-width: 1024px) {
      width: 30%;
    }
  }
  &-wrapper2 {
    width: 90%;
    @media (min-width: 1024px) {
      width: 60%;
    }
  }
}
