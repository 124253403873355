.contacts {
  &__header {
    @include header;
  }
  &__info {

    margin-top: 15px;
    @media (min-width: 1024px) {
      margin-top: 30px;
    }

    &-section {
      margin-bottom: 10px;
      @media (min-width: 1024px) {
        margin-bottom: 26px;
      }
    }
    &-title {
      font-size: $font-small1;
      line-height: 14px;
      color: $color-green;
      font-weight: 600;
      margin-bottom: 2px;
      @media (min-width: 1024px) {
        font-size: $font-normal1;
        line-height: 20px;
        margin-bottom: 6px;
      }
    }
    &-text {
      display: flex;
      align-items: center;
      font-size: $font-small1;
      line-height: 14px;
      color: $color-grey-dark;
      margin-bottom: 2px;
      @media (min-width: 1024px) {
        font-size: $font-normal1;
        line-height: 20px;
        margin-bottom: 4px;
      }

      p {
        margin-left: 6px;
        @media (min-width: 1024px) {
          margin-left: 15px;
        }

      }

      img {
        width: 10px;
        @media (min-width: 1024px) {
          width: 16px;
        }

      }

      span {
        font-weight: 600;
      }
    }
  }
}
