.service-list-columns {
    margin-top: 20px;
    @media (min-width: 1024px) {
        margin-top: 40px;
    }
    &__title {
        font-size: $font-normal1;
        max-width: 210px;
        margin-bottom: 15px;
        @media (min-width: 1024px) {
            font-size: $font-big1;
            max-width: 460px;
            margin-bottom: 30px;
        }
    }

    &__wrapper {
        display: flex;
        flex-direction: column;
        @media (min-width: 1024px) {
            flex-direction: row;
            font-size: $font-big;
            justify-content: space-between;
        }
    }

    &__column {
        display: flex;
        flex-direction: column;
        padding: 0 10px;
        @media (min-width: 1024px) {
            width: 45%;
            padding: 0 15px;
        }
    }

}
