.sell-us {
    margin-top: 25px;
    @media (min-width: 1024px) {
        margin-top: 40px;
    }
    &__phone {
        color: $color-black;
        font-weight: 700;
        font-size: $font-normal;
        @media (min-width: 1024px) {
            font-size: $font-large;
        }
        a {
            color: $color-black;
            font-weight: 700;
            text-decoration: none
        }
    }
    &__text {
        @include green-text(25px, 30px);
        padding: 18px;
        margin-top: 25px;
        @media (min-width: 1024px) {
            margin-top: 30px;
            padding: 25px 35px;
            width: 60%;
        }
    }
}