.service-rent {
    display: flex;
    flex-direction: column;

    @media (min-width: 1024px) {
        flex-direction: row;
    }
    &__info {
        &-title {
            font-size: $font-normal;
            font-weight: 600;
            color: $color-black;
            text-align: center;
            padding: 9px 14px;
            border: 3px solid $color-green;
            border-radius: 60px;
            margin-top: 20px;
        
            @media (min-width: 1024px) {
                font-size: $font-large;
                padding: 20px 30px;
                margin-top: 35px;
            }
        }

        &-subtitle {
            
            font-size: $font-small;
            font-weight: 700;
            color: $color-grey-dark;
            margin-top: 12px;

            @media (min-width: 1024px) {
                font-size: $font-normal1;
                line-height: 20px;
                margin-top: 35px;
            }
        }

        &-phone {
            display: inline-block;
            font-size: $font-small;
            font-weight: 700;
            color: $color-white;
            text-decoration: none;
            background-color: $color-green;
            border-radius: 25px;
            padding: 6px 12px;
            margin-top: 15px;
            @media (min-width: 1024px) {
                font-size: $font-normal1;
                line-height: 20px;
                border-radius: 60px;
                padding: 14px 28px;
                margin-top: 35px;
            }
        }

        &-section {
            margin: 0 auto;
            @media (min-width: 1024px) {
                width: 90%;
            }
        }
    } 
    &__img {
        // margin: 25px auto;
        width: calc(100% - 40px);

        @media (min-width: 768px) {
            width: 65%;
        }
        
        @media (min-width: 1024px) {
            width: 55%;
            display: flex;
            align-items: center;
        }
        @media (min-width: 1120px) {
            display: block;
        }

        @media (min-width: 1400px) {
            width: 35%;
        }

        .bordered-product {
          @include bordered-product-wrapper(55px, 75px, 20px, 25px);

          &__img {
            @include bordered-product-image(35px, 50px)
          }

        }
    }   

    &__text {
        font-size: $font-small1;
        color: $color-grey-dark;
        border-radius: 35px;
        border: 2px solid $color-green;
        padding: 20px;
        margin-top: 25px;
        @media (min-width: 1024px) {
            font-size: $font-normal1;
            padding: 45px 60px;
            margin: 30px auto 0 auto;
            width: 85%;
        }

        p {
            margin-bottom: 20px;
            @media (min-width: 1024px) {
                margin-bottom: 40px;
            }

            &:last-child {
                margin: 0;
            }
        }

        &-title {
            font-size: $font-normal;
            font-weight: 500;
            color: $color-black;
            margin: 0 20px;
            @media (min-width: 1024px) {
                font-size: $font-large;
                margin: 0;
                margin-top: 50px;
                max-width: 70%;
            }
        }
    }
}