@mixin horizontal-line {
    border-bottom: 1px solid $color-black;
    width: 11px;
    align-self: center;
    flex-shrink: 0;
}

@mixin white-border-button {
    padding: 7px 56px;
    border: 1px solid $color-white;
    border-radius: 29px;
    text-align: center;
    color: $color-white;
    width: 100%;
}

@mixin green-button-medium {
    background: $color-green;
    border-radius: 40px;
    color: $color-white;
}

@mixin white-border-button-medium {
    background: $color-white;
    border: 1px solid $color-green;
    border-radius: 40px;
    color: $color-black;
}

@mixin green-button-large {
    color: $color-white;
    background: $color-green;
    border-radius: 60px;
    width: 100%;
}

@mixin white-button-medium {
    padding: 10px 15px;
    border: 1px solid $color-green;
    background-color: $color-white;
    border-radius: 40px;
    width: 100%;
}

@mixin header {
  font-size: $font-large;
  color: $color-black;
  line-height: 16px;
  font-weight: 500;
  max-width: 260px;
  margin-top: 25px;
  @media (min-width: 1024px) {
    font-size: $font-big1;
    line-height: 44px;
    max-width: 600px;
    margin-top: 37px;
  }
}

@mixin green-text($radius-mobile, $radius-desktop) {
  background-color: $color-green;
  border-radius: $radius-mobile;
  color: $color-white;
  font-weight: 700;
  font-size: $font-small;
  @media (min-width: 1024px) {
    font-size: $font-normal;
    border-radius: $radius-desktop;
  }
}

@mixin bordered-text($radius-mobile, $radius-desktop) {
    border-radius: $radius-mobile;
    color: $color-grey-dark;
    font-weight: 400;
    font-size: $font-small;
    border: 4px solid $color-green;
    @media (min-width: 1024px) {
      border-radius: $radius-desktop;
      font-size: $font-normal;
  }
}

@mixin bordered-product-wrapper($radius-mobile, $radius-desktop, $padding-mobile: 20px, $padding-desktop: 25px, $border-width: 2px) {
  display: flex;
  align-items: center;
  border: $border-width solid $color-green;
  border-radius: $radius-mobile;
  padding: $padding-mobile;
  
  @media (min-width: 1024px) {
      padding: $padding-desktop;
      border-radius: $radius-desktop;
  }
}

@mixin bordered-product-image($radius-mobile, $radius-desktop) {
  width: 100%;
  border-radius: $radius-mobile;
  @media (min-width: 1024px) {
      border-radius: $radius-desktop;
  }
}

@mixin hover-underline-animation() {
  position: relative;
  text-decoration: none;

  &::after {
    content: '';
    position: absolute;
    width: 100%;
    transform: scaleX(0);
    height: 2px;
    bottom: -3px;
    left: 0;
    background-color: $color-green;
    transform-origin: bottom left;
    transition: transform 0.25s ease-out;
  }

  &:hover::after {
    transform: scaleX(1);
    transform-origin: bottom left;
  }
}

@mixin active-link-underline() {
  position: relative;
  text-decoration: none;

  &::after {
    content: '';
    position: absolute;
    width: 100%;
    transform: scaleX(0);
    height: 2px;
    bottom: -3px;
    left: 0;
    background-color: $color-green;
    transform: scaleX(1);
  }
}

@mixin navigation-link-hover() {
  cursor: pointer;
  transition: all .1s;
  &:hover {
    color: $color-green1;
  }
}

@mixin link-hover() {
  cursor: pointer;
  transition: all .1s;
}

@mixin form-input() {
  border: 1px solid #ddd;
  border-radius: 5px;
  line-height: 22px;
  margin-bottom: 8px;
  font-size: $font-small; 
  padding: 2px 5px;

  @media (min-width: 768px) {
      border-radius: 8px;
      line-height: 28px;
      margin-bottom: 20px;
      font-size: $font-normal1; 
      padding: 4px 10px;

  }
}

@mixin form-label() {
  font-size: $font-small; 
  margin-bottom: 8px;
  width: 100%;
  display: inline-block;
  text-align: start;
  @media (min-width: 768px) {
      font-size: $font-normal1; 
      margin-bottom: 10px;
  }
}

@mixin form-submit-button() {
  font-size: $font-normal; 
  margin-top: 8px;
  padding: 3px 0;
  border-radius: 8px;
  cursor: pointer;
  background-color: $color-black;
  color: $color-white;
  border-color: $color-black;
  border-width: 1px;
  transition: all .4s ease-out;

  @media (min-width: 768px) {
      margin-top: 25px;
      font-size: $font-large; 
      padding: 5px 0;
      border-radius: 10px;
      width: 60%;
  }

  &:hover {
      color: $color-black;
      background-color: $color-white;
      border: 1px solid $color-black;
  }
}

@mixin subheader {
  font-size: $font-large;
  color: $color-black;
  line-height: 18px;
  font-weight: 500;
  margin-top: 15px;
  @media (min-width: 1024px) {
    font-size: $font-big;
    line-height: 20px;
    margin: 30px 0;
  }
}