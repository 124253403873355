.login {
    width: 100%;

    display: flex;
    align-items: center;
    margin: 50px 0;
    @media (min-width: 768px) {
        margin: 150px 0;
    }

    &__wrapper {
        margin: 0 auto;
        width: 90%;
        background-color: #fff;
        padding: 40px;
        border-radius: 10px;
        box-shadow: rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px;
        text-align: center;

        @media (min-width: 768px) {
            width: 40%;
        }
    }

    &__header {
        font-size: $font-large1;
        margin-bottom: 10px;

        @media (min-width: 768px) {
            font-size: $font-big1;
            margin-bottom: 20px;
        }
    }

    &-form {
        display: flex;
        flex-direction: column;
        &__label {
            @include form-label;
        }

        &__input {
            @include form-input;
        }

        &__button {
            @include form-submit-button;
            &[disabled]{
                cursor: not-allowed;
                background-color: $color-black;
                color: $color-white;
                border-color: $color-black;
                border-width: 1px;
                opacity: .4;
            }
        }

        &__error {
            background-color: #fce4e4;
            border: 1px solid #fcc2c3;
            padding: 8px 10px;

            text-align: left;
            color: #cc0033;
            font-size: $font-normal;
            font-weight: 400;
            text-shadow: 1px 1px rgba(250,250,250,.3);
        }
    }
}