@mixin transition($speed) {
    transition: all $speed ease;
}
  
@mixin rotate($deg) {
    transform: rotate($deg);
}

.icon {
    border: 2px solid $color-green;
    @include transition(0.5s);
    &.active {
      background: transparent;
      border: 2px solid $color-green;
    }
    &:hover {
      cursor: pointer;
    }
    .icon-shape {
      &::before {
        position: absolute;
        content: "";
        top: 7px;
        left: 3px;
        width: 11px;
        height: 3px;
        background: #fff;
        border-radius: 3px;
        @include transition(0.5s);

        @media (min-width: 1024px) {
            top: 13px;
            left: 7px;
            width: 16px;
            height: 4px;
        }
      }
      &::after {
        position: absolute;
        content: "";
        top: 3px;
        left: 7px;
        width: 3px;
        height: 11px;
        background: #fff;
        border-radius: 3px;
        @include transition(0.5s);

        @media (min-width: 1024px) {
            top: 7px;
            left: 13px;
            width: 4px;
            height: 16px;
        }
      }
      &.active {
        &::before {
            background: $color-green;
          @include rotate(180deg);
          @include transition(0.5s);
        }
        &::after {
            background: $color-green;
          @include rotate(90deg);
          @include transition(0.5s);
        }
      }
    }
  }

  @mixin transition($speed) {
    transition: all $speed ease;
}
  
@mixin rotate($deg) {
    transform: rotate($deg);
}
