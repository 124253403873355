

.header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;

    @media (max-width: 1024px) {
        justify-content: flex-start;
    }

    &__extra {
      display: flex;
    }

    &__lang {
      width: 22px;
      display: flex;
      position: relative;
      align-items: center;
      margin-right: 55px;
      cursor: pointer;
      @include hover-underline-animation;

      img {
        width: 22px;
      }

      &:hover {
        .nav-panel__wrapper {
          display: block;
        }
      }

      li {
        text-transform: uppercase;
      }

    }

    &__user {
      width: 22px;
      margin-left: 20px;
    }

    &__logo {
      height: 100px;
      display: flex;
      padding: 20px 0;
      @media (max-width: 1024px) {
        margin-left: 30px;
        height: 65px;
        padding: 15px 0;
      }
      &-green-logo {
        img {
          width: 72px;
          @media (max-width: 1024px) {
            width: 38px;
          }
        }
      }
      &-text {
        padding: 5px 0;
        margin-left: 15px;
        @media (max-width: 1024px) {
          padding: 2px 0;
          margin-left: 10px;
        }

        img {
          width: 116px;
          @media (max-width: 1024px) {
            width: 72px;
          }
        }
      }
      & img {
        height: 100%;
      }
    }

    &__nav {
      width: 60%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      list-style-type: none;

      @media (max-width: 1024px) {
        display: none;
      }

      & > li {
        position: relative;

        &:hover {
          .nav-panel__wrapper {
            display: block;
          }
        }
      }

      & .header__extra > li {
        position: relative;

        &:hover {
          .nav-panel__wrapper {
            display: block;
          }
        }
      }

      &-link {
      font-size: $font-large;
      line-height: 26px;
      color: $color-black;
      text-decoration: none;
      @include hover-underline-animation;

        &_active {
          @include active-link-underline;
        }
      }

      .nav-panel {
        padding: 13px 17px 13px 18px;
        background: $color-grey-light;
        border-radius: 13px;
        list-style-type: none;


        &__wrapper {
          display: none;
          position: absolute;
          top: 20px;
          padding-top: 20px;
          z-index: 28;
          
        }

        & > li {
          margin-top: 5px;
          &:first-child {
            margin-top: 0;
          }
          &:hover {
            .nav-panel__hor-line {
              border-color: $color-green1;
            }
            .nav-panel__link {
              color: $color-green1;
            }
            .nav-panel__link-item {
              color: $color-green1;
            }
          }
        }

        &__hor-line {
            @include horizontal-line;
            transition: all .1s;
        }

        &__link {
          display: flex;

          max-width: 150px;
          font-size: $font-normal;
          line-height: 20px;
          color: #000;
          text-decoration: none;
          transition: all .1s;
          cursor: pointer;

          &-item {
            margin-left: 7px;
          }
        }
      }
    }

    &-mobile {

        &__wrapper {
          z-index: 100;
          position: absolute;
          top: 65px;
          left: 0;
          height: calc(100vh - 65px);
          display: flex;
          flex-direction: column;
          display: none;
          background-color: $color-green;
          width: 65%;
          padding: 35px 30px 35px 20px;
          transform-origin: left center;
          animation: append-animate .3s ease-out;

          @media (max-width: 1024px) {
              display: block;
          }
        }
        &__login {
            display: inline-block;
            font-size: $font-normal;
            color: $color-white;
            margin-bottom: 10px;
        }

        &__search-box {
            display: block;
            background-color: transparent;
            font-size: $font-small;
            @include white-border-button;
        }

        &__extra-links {
          .nav-panel {
            position: static;
            display: flex;
            flex-direction: column;
            font-size: $font-small;
            line-height: 15px;
            color: $color-black;
            list-style-type: none;
            padding: 0;
            margin-top: 10px;
    
            &__hor-line {
                @include horizontal-line;
                margin-right: 5px;
            }
    
            &__link {
              display: flex;
              margin-bottom: 6px;
              text-transform: uppercase;
              &:last-child {
                  margin-bottom: 10px;
              }
            }
          }
          .extra-links__lang {
            width: 20px;
            height: 20px;
          }
          svg {
            width: 25px;
            height: 25px;
            fill: white;
          }
        }
    }

    &-mobile__nav {
        display: flex;
        flex-direction: column;
        list-style-type: none;
        position: relative;
        padding: 0;
        margin: 0;


      &-link {
        display: block;
        font-weight: 600;
        font-size: $font-small;
        line-height: 14px;
        margin-bottom: 15px;
        text-decoration: none;

        @include white-border-button;
      }

      .nav-panel {
        position: static;
        display: flex;
        flex-direction: column;
        font-size: $font-small;
        line-height: 15px;
        color: $color-black;
        list-style-type: none;
        padding: 0;


        &__hor-line {
            @include horizontal-line;
        }

        &__link {
            margin-bottom: 6px;

            a {
              display: flex;
              max-width: 100%;

              color: $color-black;
              text-decoration: none;
                 
              span {
                margin-left: 5px;
              }
            }

            &:last-child {
                margin-bottom: 10px;
            }

            &_active {
              span {
                color: $color-white;
              }
              .nav-panel__hor-line {
                border-color: $color-white;
              }
            }
        }
      }
    }
  }


@keyframes append-animate {
	from {
		left: -100%;
	}
	to {
		left: 0;
	}
}
