$big-image-max-width: 650px;
$big-image-max-height: 480px;
// $big-image-max-height: 600px;



.product-swiper {
    position: relative;
    .swiper-slide {
        border-radius: 10px;
        overflow: hidden;
        display: flex;
        align-items: center;
        @media (min-width: 1024px) {
          border-radius: 20px;
        }
        img {
            width: 100%;
            border-radius: 10px;
            @media (min-width: 1024px) {
              border-radius: 20px;
            }
        }
    }

    .swiper-active {
        margin: 0 20px 5px;


        @media (min-width: 1024px) {
            max-height: $big-image-max-height;
            max-width: $big-image-max-width;
            margin: 0 30px 10px;
        }

        .swiper-slide {
            @media (min-width: 1024px) {
                max-height: $big-image-max-height;
                max-width: $big-image-max-width;
                overflow: hidden;
            }
            &.swiper-slide-active, &.swiper-slide-next {
                img {
                    @media (min-width: 1024px) {
                        max-height: 100%;
                        overflow: hidden;
                    }
                }
                width: 100%;
                border-radius: 25px;
                @media (min-width: 1024px) {
                    border-radius: 55px;
                }
            }
        }
    }

    --swiper-theme-color: #B1CD54;
    --swiper-navigation-size: 20px;    

    @media (min-width: 1024px) {
        --swiper-navigation-size: 30px;    

      }
}

.swiper-button-prev, .swiper-button-next {
    background: transparent;
    border: none;
    position: absolute;
    top: 90%;
}

.swiper-button-next {
    right: 0;
}

.swiper-button-prev {
    left: 0;
}


.swiper-thumb {
    margin: 0 20px;

    @media (min-width: 1024px) {
        margin: 0 30px;
    }

    .swiper-wrapper {
        justify-content: center;
    }

    .swiper-slide {
        max-width: 164px;
        max-height: 108px;
    }

    .swiper-slide {
        background-color: $color-black;
        img {
            // opacity: .5;
 
        }
        &-active {
            img {
                opacity: 1;

            }
        }
    }
}

