.service-columns {
    display: flex;
    flex-direction: column;
    @media (min-width: 1024px) {
        flex-direction: row;
    }
    .service-column {
        &__wrapper1 {
            width: 85%;
            @media (min-width: 1024px) {
                width: 85%;
            }
        }
        &__wrapper2 {
            width: 65%;
            @media (min-width: 1024px) {
                margin-right: 43px;
                float: right;
                width: 60%;
            }
        }
        width: 100%;
        @media (min-width: 1024px) {
            width: 50%;
        }
        &__header {
            font-size: $font-normal1;
            margin-bottom: 15px;
            @media (min-width: 1024px) {
                text-align: center;
                flex-direction: row;
                font-size: $font-big;
            }
        }
    }
}