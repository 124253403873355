.about-us {
    display: flex;
    flex-direction: column;
    @media (min-width: 1024px) {
        flex-direction: row;
        justify-content: space-between;
    }
    &__block {
        margin-top: 25px;
        @media (min-width: 1024px) {
            flex: 0 0 45%;
            align-self: flex-end;
            margin-top: 50px;
            margin-bottom: auto;
        }

        .bordered-product {
            @include bordered-product-wrapper(55px, 75px, 18px, 20px);
  
            &__img {
              @include bordered-product-image(35px, 50px)
            }
          }
    }
    &__text {
        @include green-text(35px, 40px);
        padding: 20px;
        @media (min-width: 1024px) {
            padding: 20px 40px;
        }
    }
}

.about-us-page.nav-bar__wrapper {
    width: 60%;
    @media (min-width: 1024px) {
        width: 25%;
    }
}