.product-item {
  display: flex;
  flex-direction: column;
  margin: 18px 8px 0;
  text-decoration: none;
  z-index: 5;

  @media (min-width: 1024px) {
    margin: 40px 15px 0;
  }

  &__category {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 80%;
  }

  &__wrapper {
    position: relative;
    transition: all .5 ease;
    &:hover .product-item__overlay{
      top: 0;
      height: 25%;
      transition: all .5 ease;

    }
  }

  &__overlay {
    display: flex;
    background-color: rgba($color: #000000, $alpha: .25);
    // height: 25%;
    position: absolute;
    // top: 0;
    z-index: 20;
    width: calc(100% - 16px);
    margin-left: 8px;
    border-top-right-radius: 20px;
    border-top-left-radius: 20px;

    top: 100%;
    height: 0;
    transition: all .5 ease;
    overflow: hidden;

    @media (min-width: 1024px) {
      border-top-right-radius: 30px;
      border-top-left-radius: 30px;  
      margin-left: 15px; 
      width: calc(100% - 30px);
     }
  }

  &__icon {
    svg {
      width: 45px;
      height: 45px;
      fill: white;
      @include link-hover;
    }


  }


  &__icon.edit {
    position: absolute;
    top: 20px;
    left: 35px;
    svg:hover {
      fill: $color-green;
    }
  }

  &__icon.cross {
    position: absolute;
    top: 20px;
    right: 35px;
    svg:hover {
      fill: red;
    }
    svg {
      width: 50px;
      height: 50px;
    }
  }

  &__img {

    img {
      width: 100%;
      border-radius: 20px;
      object-fit: cover;
      height: 160px;
      @media (min-width: 1024px) {
        border-radius: 30px;
        height: 270px;
      }
    }
  }

  &__info {
    display: flex;
    flex-direction: column;

    &-category {
      display: flex;
      justify-content: space-between;
      font-size: $font-small1;
      line-height: 12px;
      color: $color-grey-dark;
      margin-top: 8px;
      @media (min-width: 1024px) {
        font-size: $font-small;
        line-height: 15px;
        margin-top: 12px;
      }
    }

    &-price {
      font-weight: 600;
      margin-top: 4px;
      @media (min-width: 1024px) {
        font-size: $font-large;
        margin-top: 8px;
      }
    }

    &-img {
      width: 14px;
      margin-right: 8px;
      @media (min-width: 1024px) {
        width: 18px;
        margin-right: 15px;
      }
    }

    &-text {
      color: black;
      display: flex;
      flex-direction: column;
      font-size: $font-small;
      margin-top: 3px;
      @media (min-width: 1024px) {
        margin-top: 4px;
        font-size: $font-normal1;
      }
    }
  }

}
