.service-text {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    padding: 11px 10px;
    border: 3px solid $color-green;
    border-radius: 60px;
    margin: 30px 0;

    @media (min-width: 1024px) {
        padding: 15px 40px;
        margin: 80px auto;
        width: 75%;
    }

    &__info {
        display: flex;
        flex-direction: column;
        text-align: start;
        font-size: $font-small;
        line-height: 20px;
        max-width: 75%;
        @media (min-width: 1024px) {
            line-height: 26px;
            max-width: 580px;
        }
    }
    &__title {
        font-weight: 600;
        color: $color-black;
        @media (min-width: 1024px) {
            font-size: 24px;
            max-width: 580px;
        }
    }

    &__text {
        color: $color-grey-dark;
        @media (min-width: 1024px) {
            font-size: $font-normal1;
            max-width: 580px;
        }
    }

    &__icon {
        width: 36px;
        height: 36px;
        margin-right: 10px;
        @media (min-width: 1024px) {
            width: 63px;
            height: 63px;
            margin-right: 20px;
        }

      img {
        width: 100%;
      }
    }
}
