.clients {
    &__header {
        @include header;
        color: $color-green;
        max-width: 90%;
    }
    .choice-details {
        &_bordered {
            @include bordered-text(40px, 80px);

            margin: 0 auto;
            padding: 20px;
            margin-top: 20px;
            @media (min-width: 768px) {
                width: 85%;
                padding: 35px 45px;
                margin-top: 30px;
            }
        }

        &__img {
            border-width: 4px;
            &:first-child {
                @media (min-width: 768px) {
                    width: 55%;
                }
                .bordered-product {
                    margin: 0 auto;
                    @media (min-width: 768px) {
                       max-width: 570px;
                     }
                    @include bordered-product-wrapper(55px, 90px, 20px, 25px, 4px);
          
                    &__img {
                      @include bordered-product-image(35px, 50px)
                    }
                }
            }
            &:last-child {
                display: none;
                @media (min-width: 768px) {
                    &:last-child {
                        display: block;
                        width: 45%;
                        margin-top: 200px;
                    }
                }
                .bordered-product {
                    @media (min-width: 768px) {
                        max-width: 460px;
                        margin-left: 40px;
                      }
                    @include bordered-product-wrapper(50px, 65px, 20px, 25px, 4px);
          
                    &__img {
                      @include bordered-product-image(35px, 50px)
                    }
                }
            }

            &-text {
                color: $color-grey-dark;
                font-size: $font-small;
                margin-top: 15px;
                @media (min-width: 768px) {
                    font-size: $font-normal;
                    margin-top: 40px;
                }
            }

            &-block {
                display: flex;
                margin-top: 20px;
                @media (min-width: 768px) {
                    margin-top: 50px;
                }
            }
        }
    }
    .technical-details {
        display: flex;
        flex-direction: column;

        &__header {
            width: 100%;
            display: inline-block;
    
            @media (min-width: 768px) {
                align-self: flex-end;
                width: 80%;
            }
        }
        &__block {
            width: 100%;
            margin-top: 20px;
            @media (min-width: 768px) {
                margin-top: 40px;
            }
            &_bordered {
                align-self: flex-end;
                padding: 20px;

                @include bordered-text(40px, 80px);

                @media (min-width: 768px) {
                    width: 80%;
                    padding: 35px 45px;
                }
            }
            &_green {
                padding: 20px;
                @include green-text(40px, 80px);

                @media (min-width: 768px) {
                    width: 60%;
                    padding: 30px 55px;
                    &:first-child {
                        align-self: flex-start;
                    }
                    &:last-child {
                        align-self: flex-end;
                    }
                }
             
            }
        }
    }
}