body {
    padding: 0;
    margin: 0;
    overflow-x: hidden;
}

*, *:before, *:after {
    font-family: 'Geologica';
    box-sizing: border-box;
    margin: 0;
    padding: 0;
  }

.app-container {
    margin: 0 16px;
    @media (min-width: 768px) {
        margin: 0 24px;
    }
    @media (min-width: 1440px) {
        max-width: 1440px;
        margin: 0 auto;
    }
}

.content-container {
    padding: 15px 24px 20px;
    border: 1px solid $color-black;
    border-radius: 35px;
    min-height: calc(100vh - 373px);
    @media (min-width: 1024px) {
        padding: 27px 70px 60px;
    }
}



