body {
    position: relative;
}

$font-small1: 12px;
$font-small: 14px;
$font-normal: 16px;
$font-normal1: 18px;
$font-large: 20px;
$font-large1: 22px;

$font-large2: 26px;

$font-big: 30px;
$font-big1: 35px;

$color-black: #000;
$color-white: #fff;
// $color-green: #3A3A76;

$color-green: #B1CD54;
$color-green1: #B1DB26;


$color-grey-light: #EFECEC;
$color-grey-dark: #656262;
