.create-tag {
    width: 100%;

    display: flex;
    align-items: center;
    margin-top: 40px;

    @media (min-width: 768px) {
        margin-top: 70px;
    }

    &__wrapper {
        margin: 0 auto;
        width: 90%;
        background-color: #fff;
        padding: 40px;
        border-radius: 10px;
        box-shadow: rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px;
        text-align: center;

        @media (min-width: 768px) {
            width: 80%;
        }
    }

    &__section.section2 {
        width: 80%;
        margin: 0 auto;
    }

    &__header {
        font-size: $font-large1;
        margin-bottom: 10px;
        text-align: left;

        @media (min-width: 768px) {
            font-size: $font-big1;
            margin-bottom: 30px;
        }
    }


    &__subheader {
        @include subheader;
    }

    &__form {
        display: flex;
        flex-direction: column;
        padding-bottom: 50px;
    }

    &__label {
        @include form-label;
    }

    &__input {
        @include form-input;
        
        @media (min-width: 768px) {
            margin-bottom: 20px;
        }
    }

    &__button {
        @include form-submit-button;
    }

    &__table {
        display: flex;
        flex-direction: column;

        &-row {
            display: flex;
            border-bottom: 1px solid #ddd;
            padding: 8px 4px;

            &:last-child {
                border: none;
            }
        }

        &-input {
            text-align: center;
            @include form-input;
            width: 80%;
            @media (min-width: 768px) {
                margin: 0;            }
        }

        &-icon {
            @include link-hover;
            svg {
                width: 28px;
                height: 28px;
                cursor: pointer;
               
            }
            &.cross, &.cancel {
                &:hover {
                    fill: red;
                }
            }

            &.cancel {
                width: auto;
            }

            &.edit {
                &:hover {
                    fill: $color-green;
                }
            }

            width: 10%;

        }

        &-text {
            width: 70%;
            font-size: 20px;
            line-height: 32px;
        }

    }

}