.product-list {
  display: flex;
  flex-wrap: wrap;
  margin: 0 auto;

  @media (min-width: 1024px) {
    max-width: 1200px;
  }

  &__header {
    max-width: 1200px;
    margin: 0 auto;
  }
}

app-product-item {
  flex: 0 0 50%;
  max-width: 50%;
  @media (min-width: 1024px) {
    flex: 0 0 25%;
    max-width: 25%;
  }
}

app-product-list {
  .filters {
    display: flex;
    justify-content: flex-end;
    // background: #b1cd5412;
    padding: 5px 0;

    @media (min-width: 768px) {
      max-width: 1200px;
      margin: 0 auto;
    }
    

    &__name {
      text-transform: uppercase;
      white-space: nowrap;


      &-sel {
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        @media (min-width: 768px) {
          margin-left: 5px;
        }
      }
    }

    &__sort {
      border-right: 2px solid #e5e5e5;
      position: relative;
      display: flex;
      cursor: pointer;

      // @media (min-width: 768px) {
      //   width: 235px !important;
      // }

      &-variants {
        position: absolute;
        z-index: 11;
        padding: 13px 17px 13px 18px;
        background: $color-grey-light;
        border-radius: 13px;
        white-space: nowrap;
        text-transform: lowercase;
        top: 32px;
        left: 0;
        @media (min-width: 768px) {
          top: 32px;
          left: 0;
          margin-left: 20px;
        }

        &_var {
          @media (min-width: 768px) {
            padding: 2px 0;
            @include link-hover;
            &:hover {
              color: $color-green;
            }
          }
        }
      }
    }

    & > div {
      width: 50%;
      text-align: center;
      padding: 5px 10px;
      cursor: pointer;
      @media (min-width: 768px) {
        font-size: $font-normal1;
        width: auto;
        padding: 2px 20px;

      }
    }
  }
}