.nav-bar {
    display: flex;
    flex-wrap: wrap;
    &__link {
        padding: 6px 8px;
        font-size: $font-small;
        margin: 0 15px 10px 0;
        text-decoration: none;
        transition: all .25s ease-out;
        @include white-border-button-medium;
        @media (min-width: 1024px) {
            font-size: $font-normal;
            padding: 10px 15px;
        }
        &:hover, &_active {
            @include green-button-medium;
        }

    }
}