.service-list-item {
    display: flex;
    align-items: center;
    margin-bottom: 20px;

    @media (min-width: 1024px) {
        justify-content: start;
        margin-bottom: 15px;
    }
    &__icon {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 27px;
        height: 25px;
        img {
            width: 27px;
        }
    }
    &__text {
        font-size: $font-small;
        line-height: 16px;
        color: $color-grey-dark;
        margin-left: 17px;
        @media (min-width: 1024px) {
            font-size: $font-normal1;
            line-height: 26px;
            margin-left: 15px;
            max-width: 500px;
        }
    }

}

