app-filter-modal {
    position: absolute;
    top: 0;
    left: 0;

    .filters {
        &__list {

            overflow: auto;
            height: calc(100% - 69px);
            display: flex;
            flex-direction: column;
            @media (min-width: 768px) {
                height: calc(100% - 89px);
            }

            &-item {
                border-bottom: 1px solid #e5e5e5;
                padding: 15px 20px 10px 20px;
                @media (min-width: 768px) {
                    padding: 15px 25px 10px 25px;
                }
                &__name {
                    font-size: $font-normal1;
                    margin: 5px 0 10px 0;
                    @media (min-width: 768px) {
                        margin: 10px 0 15px 0;
                    }
                }

                &__icon {
                    @media (min-width: 768px) {
                        font-size: 22px;                
                    }
                    display: flex;
                    align-items: center;

                    &--opened {
                        transform: rotateZ(-180deg);
                    }

    
                }

                &__wrapper {
                    display: flex;
                    justify-content: space-between;
                    align-items: center;

                    &:hover {
                        cursor: pointer;
                        .filters__list-item__icon {
                            @include link-hover;
                            fill: $color-green;
                        }

                    }
                }
            }
        }

        &__range-inputs {
            display: flex;
            .mat-mdc-form-field {
                margin: 0 5px;
            }
        }
    }

    .mat-mdc-slider {
        width: calc(100% - 30px);
    }

    .mat-slider.mat-slider-horizontal .mat-slider-track-fill {
        background-color: yellow;
      }
      
}
.filter-modal{
    &__overlay {
        background-color: #15151599;
        width: 100vw;
        height: 100vh;
        position: absolute;
        z-index: 100;
        @media (min-width: 768px) {
            position: static;
        }
    }

    &__top {
        display: flex;
        border-bottom: 2px solid #e5e5e5;
        justify-content: space-between;
        position: relative;
        padding: 20px 15px 15px 15px;
        @media (min-width: 768px) {
            padding: 35px 20px 20px 20px;
        }

        &-section {
            display: flex;
            justify-content: space-between;
            align-items: baseline;
            width: 80%;
            @media (min-width: 768px) {
                width: 90%;
            }
        }
    }

    &__cross {
        font-size: 25px;
        position: absolute;
        top: 15px;
        right: 20px;
        @media (min-width: 768px) {
            font-size: 30px;
            top: 15px;
            right: 20px;
        }

        &:hover {
            @include link-hover;
            fill: $color-green;
        }
    }

    &__wrapper {
        float: right;
        width: 100%;
        background-color: white;
        height: 100%;
        transform-origin: left center;
        animation: append-animate .3s ease-out;
        @media (min-width: 768px) {
            width: 23%;
            min-width: 420px;
        }
    }

    &__header {
        color: $color-black;
        font-size: $font-large2;
        @media (min-width: 768px) {
            font-size: $font-big;
        }
    }

    &__clear {
        color: $color-grey-dark;
        font-size: $font-normal;
        text-transform: uppercase;
        cursor: pointer;
        @media (min-width: 768px) {
            font-size: $font-normal1;
        }
    }

    &__submit {
        background-color: #fff;
        position: sticky;
        bottom: 5px;
        z-index: 200;
        flex-grow: 1;
        display: flex;
        align-items: end;
        margin-bottom: 20px;
        @media (min-width: 768px) {
            padding: 0px 30px;
            margin-bottom: 0;
        }

        &-button {
            position: relative;
            font-size: 14px;
            font-weight: 600;
            text-align: center;
            line-height: 16px;
            padding: 12px 17px;
            margin: 0 10px;
            z-index: 25;
            color: #fff;
            background: #B1CD54;
            border-radius: 60px;
            width: 100%;
            text-transform: uppercase;
            border: none;
            cursor: pointer;
    
            @media (min-width: 768px) {
                font-size: 18px;
                line-height: 26px;
                padding: 10px 20px;
                margin: 30px 0;
            }
        }
    }

}

@keyframes append-animate {
	from {
		right: -100%;
	}
	to {
		right: 0;
	}
}