.service-dropdown {

    &__title {
        position: relative;
        font-size: $font-small;
        font-weight: 600;
        text-align: center;
        line-height: 16px;
        padding: 8px 17px;
        z-index: 25;
        @include green-button-large;


        @media (min-width: 1024px) {
            font-size: $font-normal1;
            line-height: 26px;
            padding: 14px 28px;

        }
    }
    &__list {
        overflow: hidden;
        position: relative;
        border-radius: 12px;
        background-color: $color-grey-light;
        margin: 0;
        padding: 45px 20px 25px;
        width: 100%;
        z-index: 20;
        display: none;
        transition: .3 ease-in-out;


        @media (min-width: 1024px) {
            border-radius: 20px;
            padding: 60px 20px 15px;

        }
        &-item {
            font-size: $font-small;
            line-height: 16px;
            color: $color-grey-dark;
            margin-left: 10px;
            margin-bottom: 10px;

            @media (min-width: 1024px) {
                font-size: $font-normal1;
                line-height: 26px;
                margin-left: 20px;
            }
        }
        &.active {
            display: block;
            top: -27px;
            animation: growDown 300ms ease-in-out forwards;
            transform-origin: top center;

            @media (min-width: 1024px) {
                top: -40px;

            }
        }
    }

    &__circle {
        position: absolute;
        top: 6px;
        right: -28px;
        width: 20px;
        height: 20px;
        border-radius: 50%;
        background-color: $color-green;
        @media (min-width: 1024px) {
            top: 10px;
            right: -43px;
            width: 33px;
            height: 33px;
        }
    }
}

@keyframes growDown {
    0% {
        transform: scaleY(0);
    }
    80% {
        transform: scaleY(1.1);
    }
    100% {
        transform: scaleY(1);
    }
}